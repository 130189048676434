import React, { useState } from "react";
import { useSelector } from "react-redux";
import GeneratePdf from "../../../Utils/GeneratePdf";

const IncomeStatement = () => {
	const [FromDate, setFromDate] = useState(
		new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			1
		).toLocaleDateString("en-CA")
	);
	const [ToDate, setToDate] = useState(
		new Date(
			new Date().getFullYear(),
			new Date().getMonth() + 1,
			0
		).toLocaleDateString("en-CA")
	);

	const firstDayUnix = new Date(FromDate).getTime();
	const lastDayUnix = new Date(ToDate).getTime();

	const Expenses = useSelector((state) => state.expense.expenses).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			item.linker >= new Date(FromDate).getTime() &&
			item.linker <= new Date(ToDate).getTime()
	);

	const PayrollCats = useSelector(
		(state) => state.payrollCat.payrollCats
	).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			item.linker >= new Date(FromDate).getTime() &&
			item.linker <= new Date(ToDate).getTime()
	);
	const PayrollItems = useSelector(
		(state) => state.payrollItem.payrollItems
	).filter((item) => parseInt(item.deleted) === 0);

	const PayrollEntries = useSelector(
		(state) => state.payrollEntry.payrollEntries
	).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			item.month >=
				new Date(new Date(FromDate).getTime()).toISOString().slice(0, 7) &&
			item.month <=
				new Date(new Date(ToDate).getTime()).toISOString().slice(0, 7)
	);

	const FilteredPayrollEntries = PayrollEntries.filter((item) =>
		PayrollItems.some(
			(payrollItem) =>
				payrollItem.linker === item.itemLinker &&
				PayrollCats.some(
					(payrollCat) =>
						payrollCat.linker === payrollItem.catLinker &&
						["Income", "Allowance"].includes(payrollCat.type)
				)
		)
	);

	const ShopEntries = useSelector((state) => state.entry.entries).filter(
		(entry) => parseInt(entry.deleted) === 0
	);

	// Filter transactions within the date range
	const filteredData = ShopEntries.filter(
		(item) => item.linker >= firstDayUnix && item.linker <= lastDayUnix
	);

	// Inventory management object to track product batches
	const inventory = {};

	// Helper function to process FIFO and calculate COGS
	const processFIFO = (productLinker, quantity) => {
		if (!inventory[productLinker] || inventory[productLinker].length === 0)
			return 0;

		let remainingQuantity = quantity;
		let costOfGoodsSold = 0;

		// Loop through the inventory FIFO queue
		while (remainingQuantity > 0 && inventory[productLinker].length > 0) {
			const batch = inventory[productLinker][0]; // Get the first batch (FIFO)
			const batchQuantity = batch.quantity;
			const unitCost = batch.unitCost;

			if (batchQuantity <= remainingQuantity) {
				// Use up the entire batch
				costOfGoodsSold += batchQuantity * unitCost;
				remainingQuantity -= batchQuantity;
				inventory[productLinker].shift(); // Remove the batch
			} else {
				// Use part of the batch
				costOfGoodsSold += remainingQuantity * unitCost;
				batch.quantity -= remainingQuantity;
				remainingQuantity = 0;
			}
		}

		return costOfGoodsSold;
	};

	// Process each transaction in the filtered ShopEntries
	let totalCOGS = 0;
	filteredData.forEach((transaction) => {
		const { productLinker, amount, quantity, type } = transaction;
		const unitCost = amount / quantity; // Calculate unit cost

		// Initialize inventory for the product if not already done
		if (!inventory[productLinker]) {
			inventory[productLinker] = [];
		}

		switch (type) {
			case "purchase":
			case "return-sale": // Increases stock, restocks items
				// Add to inventory (FIFO)
				inventory[productLinker].push({ quantity, unitCost });
				break;

			case "sale":
				// Calculate COGS using FIFO for the quantity sold
				const cogsForSale = processFIFO(productLinker, quantity);
				totalCOGS += cogsForSale;
				break;

			case "return-purchase": // Decreases stock, refunds items
				// Remove from inventory (FIFO) like a sale (but no COGS adjustment)
				processFIFO(productLinker, quantity);
				break;

			case "damaged": // Damaged items decrease stock
				// Remove damaged items from inventory (FIFO)
				processFIFO(productLinker, quantity);
				break;

			default:
				break;
		}
	});

	console.log(`Total COGS between ${FromDate} and ${ToDate}: ${totalCOGS}`);

	return (
		<div>
			<div className="row justify-content-center">
				<div className="col-md-6 col-lg-5 col-xl-4 card m-1" id="Profit&Loss">
					<div className="d-flex justify-content-around card-header">
						<strong>P$L A/C </strong>{" "}
						<div>
							<strong>From : </strong>
							<input
								type="date"
								className="rounded"
								value={FromDate}
								onChange={(e) => setFromDate(e.target.value)}
							/>
						</div>
						<div>
							<strong>To : </strong>{" "}
							<input
								type="date"
								className="rounded"
								value={ToDate}
								onChange={(e) => setToDate(e.target.value)}
								min={FromDate}
							/>
						</div>
					</div>
					<div className="card-body">
						<table className="table table-sm table-striped">
							<tr>
								<th>Sales</th>

								<td>
									{filteredData
										.filter((entry) => entry.type === "sale")
										.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th>Cost Of Goods Sold</th>
								<td>-{totalCOGS}</td>
							</tr>
							<tr>
								<th className="text-decoration-underline">Gross Profit</th>
								<td className="text-decoration-underline">
									{filteredData
										.filter((entry) => entry.type === "sale")
										.reduce((a, b) => +a + +b.amount, 0) - totalCOGS}
								</td>
							</tr>
							<tr>
								<th>Salaries</th>
								<td>
									-{FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th>Expenses</th>
								<td>-{Expenses.reduce((a, b) => +a + +b.amount, 0)}</td>
							</tr>
							<tr>
								<th className="text-decoration-underline">
									Net Profit Before Tax
								</th>
								<td className="text-decoration-underline">
									{filteredData
										.filter((entry) => entry.type === "sale")
										.reduce((a, b) => +a + +b.amount, 0) -
										totalCOGS -
										FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0) -
										Expenses.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<GeneratePdf
				id="Profit&Loss"
				name={`Profi&Loss-${FromDate}-${ToDate}`}
			></GeneratePdf>
		</div>
	);
};

export default IncomeStatement;
